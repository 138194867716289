.contact {
  margin-top: 100px;
  overflow-y: hidden;
  .contact-image {
    width: 100%;
    height: 100%;
  }

  .mt-200 {
    margin: 50px 0px 20px 0px;
  }
  .btn-color {
    background-color: #4D148C;
    color: #ffff;
  }
  .btn-color:hover {
    background-color: #4D148C;
    color: #ffff;
  }
  // .MuiInputBase-formControl {
  //   width: 80%;
  // }
  .ryt {
    color: #66bb6a;
  }
 
}
@media only screen and (max-width: 600) {
 
  .contact {
    margin-top:70px;
    .MuiInputBase-formControl {
      width: 98%;
    }
    .min-font-size {
      font-size: 38px;
    }
    .btn-color {
      background-color: #4D148C;
      color: #ffff;
      margin-left: 113px;
    }
    .contact-image {
      display: none;
    }
  }
  
}

.header {
  flex-grow: 1;
  .badgeBgColor{
    background-color: #4D148C;;
  }
  .appBar {
    color: #6f6f6f;
    background-color: #fff;
    .title {
      flex-grow: 1;
      cursor: pointer;
      max-width: 300px;
    }
    .appLink {
      flex-grow: 1;
      display: "flex";
    }
    .link {
      color: #000;
      font-weight: 500;
      padding-right: 30px;
    }
    .link:hover {
      text-decoration: none;
    }
    .menuButton {
      margin-right: 16px;
    }

    .search {
      display: flex;
      position: relative;
      margin-left: 0;
      width: auto;
      border: 1px solid;
      border-radius: 20px;
      .searchIcon {
        width: 40px;
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .search-box {
      position: absolute;
      right: 0;
      left: 0;
      z-index: 1;
      background-color: #fff;
      padding: 4px;
      border-radius: 4px;
      margin-top: 4px;
      width: 400px;
      min-height: 150px;
      max-height: 450px;
      overflow-y: auto;
      overflow-x: hidden;
      .search-option {
        border: 1px solid #ccc;
        border-radius: 20px;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: 700;
        color: darkgray;
      }
      .active {
        background-color: #4D148C;
        color: #fff;
        border-color: #4D148C;
      }
      .progressColor{
        color: #4D148C;
      }
      .progressBgColor{
        background: #f8e5df;
      }
      .progressBgBarColor{
        background-color: #4D148C;
      }
      .item:hover {
        background: #f5f5f5;
      }
      .item-info {
        font-size: 0.5rem;
        padding-left: 10px;
        color: gray;
      }
    }
    .inputRoot {
      color: inherit;
    }
    .inputInput {
      padding: 8px 8px 8px 40px;
      transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 120px;
    }
    .inputInput:focus,
    .active-input {
      width: 200px;
    }
    .green {
      color: #fff;
      background-color: #1B71FB
    }
    .navbar__link--active {
      color: #1B71FB
    }
    // .navbar_active{
    //   color: #43a047;
    // }
  }
}
.typography {
  padding: 16px;
  background-color: #f3fdff;
  word-break: break-word;
  .read {
    color: #9e9e9e;
    cursor: pointer;
    padding: 4px;
  }
  .unread {
    color: #424242;
    cursor: pointer;
    padding: 4px;
  }
  .messageBox {
    max-height: 200px;
    overflow: auto;
  }
}

@media only screen and (min-width: 992px) {
  .header {
    .appBar {
      .sectionMobile {
        display: none;
      }
      .sectionDesktop {
        display: block;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .header {
    .appBar {
      .title {
        max-width: 100% !important;
      }
      .search {
        display: none;
      }
      .sectionDesktop,
      .appLink {
        display: none;
      }
      .sectionMobile {
        display: flex;
      }
    }
  }
}

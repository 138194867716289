.detailsPitch {
  margin-top: 35px;
  .edit-pitch {
    box-shadow: none;
  }
  .idea-title {
    font-weight: bold;
    text-transform: capitalize;
  }
  .title {
    margin-top: 5px;
    margin-bottom: 10px;
    .name {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 500;
      color: #000;
      .edit-team {
        cursor: pointer;
        font-size: 0.7rem;
        padding: 4px;
        font-weight: 500;
      }
      .edit-team:hover {
        text-decoration: underline;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
      }
    }
    .link {
      background: #eee;
      padding: 10px;
      border-radius: 10px;
      word-break: break-all;
    }
    .link:hover {
      padding: 10px;
      box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
    }
    .type {
      color: #707070;
      margin-bottom: 0;
      .other {
        font-size: 0.8rem;
      }
    }
    .team {
      color: #999;
      font-size: 0.8rem;
      span:hover {
        cursor: pointer;
        color: #0056b3;
        text-decoration: underline;
      }
    }
  }
  .idea-image {
    border-radius: 20px;
    object-fit: cover;
  }
  .icons {
    position: absolute;
    top: 5px;
    right: 5px;
    display: grid;
    background: #f7f7f7;
    padding: 5px;
    border-radius: 20px;
    .icon {
      cursor: pointer;
    }
    .icon:hover {
      color: #fff;
      background: #4d148c;
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
    }
    .icon-top:hover {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    .icon-bottom:hover {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
  .idea-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
    .grid-list {
      flex-wrap: wrap;
      transform: translateZ(0);
    }
  }
  .paper {
    .mh-5 {
      max-height: 500px;
      min-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 2px 3px;
    }
    .header {
      text-align: center;
      background: #ccc;
    }
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    .comment-box {
      display: flex;
      width: 100%;
      margin: 2px 2px;
      .MuiBadge-root {
        flex-shrink: unset;
        .MuiBadge-anchorOriginBottomRightRectangle {
          right: 18px;
        }
      }
      .badge-like {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding-right: 3px;
        .icon {
          background: #eee;
          border-radius: 50%;
          padding: 2px;
          margin-right: 2px;
        }
      }
      .avatar {
        margin-top: 3px;
        margin-right: 0.3rem;
        width: 32px;
        height: 32px;
      }
      .MuiFilledInput-input {
        padding: 10px;
      }
      .text {
        font-size: 0.8rem;
        .title {
          font-weight: 600;
          padding-right: 5px;
          font-size: 0.7rem;
        }
        background: #f8f8f8;
        border-radius: 20px;
        padding: 8px 10px;
        .menu-icon {
          color: #f8f8f8;
          cursor: pointer;
          outline: none;
        }
      }
      .text:hover {
        .menu-icon {
          color: #212529;
        }
      }
      .edit-form {
        padding-bottom: 4px;
        width: 95%;
        .MuiOutlinedInput-input {
          padding: 8px;
        }
      }
    }
    .comment-action {
      margin-left: 50px;
      button {
        padding: 0;
      }
      .like-btn,
      .reply-btn {
        text-transform: capitalize;
        font-size: 0.8rem;
      }
      .reply-form {
        padding: 12px 5px;
        width: 95%;
        .MuiOutlinedInput-input {
          padding: 8px;
        }
        .avatar {
          margin-right: 0.3rem;
          width: 32px;
          height: 32px;
        }
      }
    }
    .comment-form {
      border-top: 1px solid #ccc;
      #comment {
        padding: 10px;
      }
    }
    .action {
      .action-send-btn {
        box-shadow: none;
        outline: none;
      }
    }
    .action-btn {
      box-shadow: none;
      outline: none;
    }
  }
  .scroll-btn {
    position: fixed;
    bottom: 45px;
    right: 15px;

    font-size: 18px;
    border: none;
    outline: none;
    background-color: #1b71fb;
    color: #fff;
    cursor: pointer;
    opacity: 0.7;
  }
  .scroll-btn:hover {
    background-color: #1b71fb;
    opacity: 1;
  }
}
.team-dialog {
  .dialog-title {
    .dialogCloseButton {
      float: right;
    }
  }
  .dialog-content {
    .team-member {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px;
      margin-bottom: 10px;
    }
    .icon-button {
      padding: 4px;
      outline: none;
    }
  }

  .main {
    background-color: red;
  }
}
.idea-chat {
  height: 500px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .message-box {
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 0;
  }
  .message-form {
    flex-direction: column;
    overflow-y: hidden;
    flex: 0 0 auto;
    outline: none;
  }
}

@media screen and (max-width: 600px) {
  .detailsPitch {
    .title {
      font-size: 14px;
      .name {
        font-size: 14px;
      }
    }
  }
}
.imageContainer {
  height: 435px;
  width: 600px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.imageContainer img {
  max-width: 100%;
  max-height: 100%;
}
@media screen and (max-width: 1024px) {
  .imageContainer {
    height: 300px;
    width: 420px;
  }
}
@media screen and (max-width: 768px) {
  .imageContainer {
    height: 200px;
    width: 750px;
  }
}
@media screen and (max-width: 450px) {
  .imageContainer {
    height: 150px;
    width: 400px;
  }
}

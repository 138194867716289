.chat {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  height: 0;
  right: 15px;
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;

  .connection-dialog {
    display: flex;
    flex-direction: column;
    flex: 0 0 250px;
    width: 250px;
    min-width: 0;
    .connections {
      width: 100%;
      height: 75vh;
      overflow-y: scroll;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .connection {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 8px 5px;
        cursor: pointer;
        .name {
          font-size: 0.9rem;
          font-weight: 500;
          .last-message {
            font-size: 0.7rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .chat-dialog {
    display: flex;
    flex-direction: column;
    .header {
      justify-content: space-between;
      .name {
        margin-right: 20px;
        font-weight: 600;
      }
      .cancel-button {
        padding: 0px;
        outline: none;
        .cancel-icon {
          color: #fff;
        }
      }
    }
    .chat-box {
      height: 350px;
      width: 350px;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .messages {
        flex-direction: column;
        overflow-y: scroll;
        flex: 1 0 0px;
        .message {
          padding: 5px;
          .message-info {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .name {
              font-weight: 500;
              margin-left: 5px;
              text-transform: capitalize;
              font-size: 0.85rem;
            }
            .time {
              font-size: 0.6rem;
              margin-left: 5px;
              margin-top: 4px;
              font-weight: 500;
              opacity: 0.4;
            }
          }
          .message-content {
            margin-left: 40px;
            margin-bottom: 0;
            font-size: 0.8rem;
            margin-top: -10px;
            line-height: 1.3;
            opacity: 0.8;
          }
        }
      }
      .message-form {
        flex-direction: column;
        overflow-y: hidden;
        flex: 0 0 auto;
        outline: none;
        .message-input {
          width: 100%;
          outline: none;
          width: 100%;
          min-height: 45px;
          border: 0;
          border-top: 1px solid #ccc;
          padding: 5px;
          resize: none;
        }
      }
      .or {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #ccc;
        line-height: 0.1em;
        color: #fff;
        margin-top: 2px;
        span {
          color: #5e5e5e;
          padding: 0 10px;
          background: #fff;
          font-size: 0.8rem;
        }
      }
    }
  }
  .header {
    background-color: #303030;
    color: #fff;
    min-width: 200px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .photo {
    width: 32px;
    height: 32px;
    background-color: dimgray;
  }
  .photo-name {
    width: 32px;
    height: 32px;
    background-color: #43a047;
    font-size: 0.9em;
    margin: 5px 0px;
  }
  .badge-position {
    top: 10px;
    right: -16px;
  }
  .badgeSecondary {
    background-color: #4D148C;
  }
}
@media only screen and (max-width: 992px) {
  .chat {
    display: none;
  }
}

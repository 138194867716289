.NotVerified{
  font-family: 'Montserrat', 'sans-serif';
    
   flex-grow:1;
   margin: 30px;
   .banner-image{
   
    background-image: url('../assets/images/authbg.jpg');
    width: 100%;
    height: 700px;
   }
   .user-image{
    
    width: 70%;
    height: 70%;
    margin-top: 100px;
   }
   .desc-name{
       margin: 50px;
       
   }
   .investor-text{
    display: inline;
    text-decoration: underline;
   }
   .contact-us{
       display: inline;
    color:#0229EB;
   }
   .descReview{
    margin: 0px 50px;  
   }

   @media (min-width:320px) and (max-width:767px) {
    .desc-name{
      margin: 0;  
    }
    .descReview{margin: 20px;}
   }
}


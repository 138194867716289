.request {
  min-height: 72.5vh;
  .message-box {
    border-radius: 10px;
    padding: 15px;
    .message-text {
        display: flex;
      margin: 10px;
    }
  }
}

.peoplePage {
  overflow-y: hidden;
  padding-bottom: 10px;
  .root {
    padding: 10px;
    border-radius: 7px;
    text-align: center;
    width: 220px;
    height: 320px;
  }
  .cardImage {
    height: 120px;
    width: 120px;
    margin: 0 auto 15px 0;
    border-radius: 50%;
    position: relative;
    z-index: 10;
    cursor: pointer;
  }

  .actionButton {
    margin-top: 70px;
    margin-bottom: 10px;
    color: #000000;
  }
  .userName {
    cursor: pointer;
    // "&:hover"{
    //   text-decoration:underline;
    //   color:#007bff;
    // }
  }
  .hrLine {
    position: relative;
    top: 69px;
   
  }

  button:focus {
    //outline: 1px dotted;
    outline: none;
  }
  .con {
    margin-left: 16px;
  }
  .MuiTab-textColorInherit {
    color: inherit;
    opacity: 1.7;
  }
  @media (min-width: 600px) {
    .MuiTab-root {
      min-width: 84px;
    }
  }
    .MuiTabs-indicator{
    background-color: #66bb6a !important;
  }

  .MuiGrid-spacing-xs-2 .MuiGrid-item {
    padding: 8px;
    padding-left: 49px;
  }
}
